import { reactive } from 'vue'
import { Voice } from '@/modules/eevee/model/voices.model'

interface State {
  voice: Voice | null
}

const state: State = reactive({
  voice: null,
})

export default function useEeveeStore() {
  return {
    state,
    getVoice(): Voice | null {
      return state.voice
    },
    setVoice(payload: Voice) {
      state.voice = payload
    },
  }
}
