
import { defineComponent, reactive, watch } from 'vue'
import { getVoice } from '@/modules/eevee/api/voices.api'
import { Voice } from '@/modules/eevee/model/voices.model'
import { errored, isLoaded, loaded, LOADING_STATE, LoadingState, isErrored, isLoading } from '@/utils/loading-state'
import DataLoader from '@/components/UI/DataLoader.vue'
import DataLoadingError from '@/components/UI/DataLoadingError.vue'
import { RouteRecordName, useRoute } from 'vue-router'
import VoiceEditor from '@/modules/eevee/components/voice/VoiceEditor.vue'
import Panel from '@/components/UI/Panel.vue'
import GoBack from '@/components/UI/GoBack.vue'
import { EeveeRoute } from '@/modules/eevee/routes'

export default defineComponent({
  name: 'EeveeVoice',
  components: { GoBack, Panel, VoiceEditor, DataLoadingError, DataLoader },
  setup() {
    const data = reactive({
      isNewVoice: false,
      voice: LOADING_STATE as LoadingState<Voice>,
    })

    const route = useRoute()

    const loadVoice = async (id: string | string[]) => {
      try {
        data.voice = LOADING_STATE
        data.voice = loaded(await getVoice(String(id)))
      } catch (e) {
        data.voice = errored(e)
      }
    }

    const updateVoice = (voice: Voice) => {
      data.voice = loaded(voice)
    }

    type WatchT = [string | string[], RouteRecordName | null | undefined]
    watch(
      (): WatchT => [route.params.id, route.name],
      ([id]: WatchT) => {
        loadVoice(id)
      }
    )

    loadVoice(route.params.id)

    return {
      data,
      isLoaded,
      isLoading,
      isErrored,
      EeveeRoute,
      updateVoice,
    }
  },
})
