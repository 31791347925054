
import { defineComponent, PropType } from 'vue'
import { Voice } from '@/modules/eevee/model/voices.model'
import { useRouter } from 'vue-router'
import { EeveeRoute } from '@/modules/eevee/routes'
import useEeveeStore from '@/modules/eevee/store/store'

export default defineComponent({
  name: 'VoicesDetailsTable',
  props: {
    voices: {
      type: Array as PropType<Voice[]>,
      required: true,
    },
  },
  setup() {
    const router = useRouter()
    const store = useEeveeStore()

    const navigateToVoice = (voice: Voice) => {
      if (voice.disabledEditing) return
      const id = voice.id || 0
      store.setVoice(voice)
      router.push({ name: EeveeRoute.EditVoice, params: { id } })
    }

    return { navigateToVoice }
  },
})
