import { getV2AuthHeaders, getRequestIdHeaders, simpleV2AuthorizedQuery } from '@/utils/fetch'
import { Voice } from '@/modules/eevee/model/voices.model'
import axios from 'axios'
import { apiErrorCatcher } from '@/api/_shared'
import { eeveeApiUrl, diglettApiUrl } from '@/modules/eevee/api/_shared'
import useEeveeStore from '@/modules/eevee/store/store'

export async function getVoice(id: string): Promise<Voice> {
  const v = useEeveeStore().getVoice()
  if (id === '0' || id === 'undefined') {
    if (v === null) {
      throw new Error('Error fetching voice from store. Go back to list view. Now. There is a < arrow above. Click it.')
    }

    return new Voice(v)
  }

  const res = await simpleV2AuthorizedQuery<Voice>(eeveeApiUrl(`admin/voices/${id}`))
  if (v !== null) {
    res.cisoName = v.cisoName
    res.cisoMissing = v.cisoMissing
    res.disabledEditing = v.disabledEditing
    res.isNft = v.isNft
  }
  return new Voice(res)
}

export async function getSystemVoices(): Promise<Voice[]> {
  const res = await simpleV2AuthorizedQuery<Voice[]>(eeveeApiUrl('admin/voices?type=system'))

  return (res || []).map((v) => new Voice(v))
}

export async function getClonedVoices(): Promise<Voice[]> {
  const res = await simpleV2AuthorizedQuery<Voice[]>(eeveeApiUrl('admin/voices?type=cloned'))

  return (res || []).map((v) => new Voice(v))
}

export async function upsertVoice(voice: Voice): Promise<Voice> {
  const patch: Partial<Voice> = {
    ...voice,
  }

  return axios
    .post(eeveeApiUrl(`admin/voices`), patch, {
      headers: { ...getRequestIdHeaders(), ...getV2AuthHeaders() },
    })
    .catch(apiErrorCatcher)
    .then((r) => r.data)
    .then((data) => new Voice(data))
}

export async function deactivateVoice(voice: Voice): Promise<unknown> {
  return axios
    .delete(eeveeApiUrl(`admin/voices/${voice.id}`), {
      headers: { ...getRequestIdHeaders(), ...getV2AuthHeaders() },
    })
    .catch(apiErrorCatcher)
}

export async function saveVoiceImage(img: File, voiceId: number): Promise<string> {
  return axios
    .post(diglettApiUrl(`share/eevee/coverimage`), img, {
      headers: {
        ...getRequestIdHeaders(),
        'Content-Type': img.type,
        'Content-Disposition': `attachment; filename="voice${voiceId}.${ext(img.name)}"`,
      },
    })
    .catch(apiErrorCatcher)
    .then((r) => r.data.url)
}

function ext(f: string): string {
  return f.slice(((f.lastIndexOf('.') - 1) >>> 0) + 2)
}
