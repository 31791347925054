import { VoiceLabels } from '@/modules/ciso/model/voice-label.model'

export class Voice {
  id?: number
  name: string
  labels: VoiceLabels
  imageUrl?: string
  vcV1Embedding?: number[]
  isNft: boolean
  active: boolean
  disabledEditing: boolean
  cisoMissing: boolean
  type: string
  cisoName?: string
  keyTech?: string
  keyModel?: string
  keyId?: string
  rating?: number
  previewUrl: string | null
  createdAt: Date
  updatedAt: Date

  constructor(doc: Partial<Voice>) {
    this.id = doc.id || 0
    this.name = doc.name || ''
    this.labels = doc.labels || []
    this.imageUrl = doc.imageUrl || ''
    this.vcV1Embedding = doc.vcV1Embedding || undefined
    this.isNft = Boolean(doc.isNft)
    this.active = Boolean(doc.active)
    this.disabledEditing = Boolean(doc.disabledEditing)
    this.cisoMissing = Boolean(doc.cisoMissing)
    this.type = doc.type || ''
    this.cisoName = doc.cisoName || ''
    this.keyTech = doc.keyTech || ''
    this.keyModel = doc.keyModel || ''
    this.keyId = doc.keyId || ''
    this.rating = doc.rating || undefined
    this.previewUrl = doc.previewUrl || null
    this.createdAt = doc.createdAt ? new Date(doc.createdAt) : new Date()
    this.updatedAt = doc.updatedAt ? new Date(doc.updatedAt) : new Date()
  }
}

export function validateVoice(voice: Voice): true | string[] {
  const errors: string[] = []

  return errors.length > 0 ? errors : true
}
